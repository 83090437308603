import React, { ReactNode } from "react";
import DesktopHeader from "../DesktopHeader";
import { UnlockGrowthBanner } from "../index";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import styles from "./styles.module.scss";

const PageWrapper = ({ children }: { children: ReactNode }) => {
  const isDesktopMode = useIsDesktopMode();

  return (
    <>
      {isDesktopMode ? <DesktopHeader /> : <UnlockGrowthBanner />}
      <div className={styles.wrapper}>{children}</div>
    </>
  );
};

export default PageWrapper;
