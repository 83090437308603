import { getCookieValue } from "./getCookieValue";

export const RITUAL_MATCHING = process.env.REACT_APP_MATCHING_DOMAIN;
export const REACT_APP_WEBAPP_URL = process.env.REACT_APP_WEBAPP_DOMAIN;

export const getUrl = (
  stripeId: string,
  tier_type: string,
  postgres_plan_id: number,
  initialPriceAmount?: number,
  percent_off?: number,
) => {
  const urlSearch = window.location.search;
  const params = new URLSearchParams(urlSearch);
  let funnelStart = params.get("funnel_start");
  const funnelid = params.get("funnelid");
  const vwoUuid = getCookieValue("_vwo_uuid");
  const pricingQueryParams = `vwo_uuid=${vwoUuid}&price=${stripeId}&postgres_plan_id=${postgres_plan_id}&tier_type=${tier_type}&funnelid=${
    funnelid || "tiers-v1"
  }&initial_amount=${initialPriceAmount || 1}${
    percent_off ? "&percent_off=" + percent_off : ""
  }`;
  let url = (urlSearch ? urlSearch + "&" : "?") + pricingQueryParams;
  if (!funnelStart) {
    funnelStart = "pricing";
    url += `&funnel_start=${funnelStart}`;
  }

  return url;
};
