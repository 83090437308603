import { useGetPricesQuery } from "../api/cms";
import { useLocation } from "react-router-dom";
import { getPlanTitle } from "../utils/pricing-content";
import useCurrentPlan, { Subscriptions } from "./useCurrentPlan";
import { getPriceFromQuery } from "../utils/getCustomerData";

const useGetSubscriptionTitle = () => {
  const plan = useCurrentPlan();
  const { state } = useLocation();
  const selectedPrice = state?.price || getPriceFromQuery();
  const { data: prices } = useGetPricesQuery(
    { stripeId: selectedPrice },
    { skip: !selectedPrice },
  );
  const params = new URLSearchParams(window.location.search);
  const mode = params.get("mode");

  const getSubscriptionTitle = (priceId: string) => {
    if (plan?.name === Subscriptions.Matching || mode) {
      return "Matching Session";
    }

    if (!state?.months && prices) {
      const price = prices?.find((price) => price.stripe_id === priceId);

      return getPlanTitle(
        price?.months,
        price?.plan?.name,
        price?.tier?.frequency,
      );
    } else {
      return getPlanTitle(state?.months, plan?.name, state?.frequency);
    }
  };

  return { getSubscriptionTitle };
};

export default useGetSubscriptionTitle;
