import React, { useEffect } from "react";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import DesktopLayout from "../Home/DesktopLayout";
import MobileLayout from "./MobileLayout";
import { getSubscriptionFromSearchParams } from "../../utils/pricing-content";
import { useLocation, useNavigate } from "react-router-dom";
import { NEW_PAGE_ROUTES } from "../../router";
import { Plans } from "../../hooks/useCurrentStep";
import PageWrapper from "../../components/PageWrapper";

const SubscriptionPlan = () => {
  const isDesktopMode = useIsDesktopMode();
  const { search } = useLocation();
  const subscription = getSubscriptionFromSearchParams(search);
  const navigate = useNavigate();

  useEffect(() => {
    if (!Object.values(Plans).includes(subscription)) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("subscription", Plans.Individual);
      const newUrl = `${
        NEW_PAGE_ROUTES.SELECT_INDIVIDUAL_PLAN
      }?${searchParams.toString()}`;
      navigate(newUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);

  return (
    <PageWrapper>
      {isDesktopMode ? <DesktopLayout /> : <MobileLayout />}
    </PageWrapper>
  );
};

export default SubscriptionPlan;
