import { useNavigate } from "react-router-dom";
import { useGetPricesQuery, useGetTiersQuery } from "../../../api/cms";
import useCurrentPlan from "../../../hooks/useCurrentPlan";
import { TierCard, Button } from "../../../components";
import styles from "./styles.module.scss";
import useCurrentTier from "../../../hooks/useCurrentTier";
import { NEW_ROUTES } from "../../../router";

const Tiers = () => {
  const plan = useCurrentPlan();
  const tier = useCurrentTier();
  const { data: tiers } = useGetTiersQuery(String(plan?.id));
  const navigate = useNavigate();
  useGetPricesQuery({ tierId: tier?.id } as any, { skip: !tier });

  return (
    <>
      <div className={styles.wrapper}>
        {tiers?.map((item) => (
          <div
            id={String(item.id)}
            key={item.id}
            onClick={() =>
              navigate(NEW_ROUTES.HOME, { state: { tier: item, plan } })
            }
          >
            <TierCard
              title={item.Name}
              subtitle={item.description}
              priceId={item.initial_price}
              selected={item.id === tier?.id}
            />
          </div>
        ))}
      </div>
      {tier && (
        <Button
          id="continue_btn"
          onClick={() => navigate(NEW_ROUTES.PRICES, { state: { tier, plan } })}
        >
          {plan.button}
        </Button>
      )}
    </>
  );
};

export default Tiers;
