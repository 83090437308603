import React from "react";
import { useGetPriceQuery } from "../../api/stripe";
import OriginalPrice from "../OriginalPrice";
import TierFeatures from "../TierFeatures";
import styles from "./styles.module.scss";
import { useLocation } from "react-router-dom";
import { Plans } from "../../hooks/useCurrentStep";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getSubscriptionFromSearchParams } from "../../utils/pricing-content";
import { getNewBillingCycleFromQuery } from "../../utils/getCustomerData";

interface TierCardDesktopProps {
  title: string;
  subtitle: string;
  selected: boolean;
  initialPrice: string;
  image: string;
  length: number;
  features: string;
}

const TierCardDesktop = ({
  title,
  subtitle,
  selected,
  initialPrice,
  image,
  length,
  features,
}: TierCardDesktopProps) => {
  const { data: price, originalPrice } = useGetPriceQuery(initialPrice);
  const { search } = useLocation();
  const subscription = getSubscriptionFromSearchParams(search);
  const isDesktopMode = useIsDesktopMode();
  const { newBillingCycle } = useFlags();
  const billingCycle = getNewBillingCycleFromQuery();

  return (
    <div
      className={`${styles.wrapper} ${
        selected ? styles["wrapper-selected"] : ""
      } ${length <= 1 ? styles["wrapper-single"] : ""} ${
        styles.wrapper_repackaging
      }`}
    >
      <div className={styles.header_wrapper}>
        <div className={styles.card_title_block}>
          <div>
            {subscription && (
              <h4 className={styles.plan_title}>{subscription}</h4>
            )}
            <h1
              className={`${styles.title} ${
                subscription === Plans.Couple ? styles.new_pricing_title : ""
              }`}
            >
              {title}
            </h1>
          </div>
          <div className={styles.price_description}>
            <p className={styles.starting_at}>Starting at</p>
            {!billingCycle && (
              <div className={`${styles.price} ${styles.price_repackaging}`}>
                <OriginalPrice price={price} originalPrice={originalPrice} />
                {!isDesktopMode ? (
                  <h1 className={styles.title}>${price}</h1>
                ) : (
                  <h2>${price}</h2>
                )}
                <p>{`${isDesktopMode ? "per" : "/"} month`}</p>
              </div>
            )}
            {newBillingCycle && billingCycle && (
              <div className={`${styles.price} ${styles.price_repackaging}`}>
                <OriginalPrice price={price} originalPrice={originalPrice} />
                {!isDesktopMode ? (
                  <h1 className={styles.title}>${price / 4}</h1>
                ) : (
                  <h2>${price / 4}</h2>
                )}
                <p>{`${isDesktopMode ? "per" : "/"} week`}</p>
              </div>
            )}
          </div>
        </div>
        {isDesktopMode && <img src={image} alt="card_image" />}
      </div>

      <p
        style={{ paddingRight: length <= 1 ? 0 : "32px" }}
        className={styles.description}
      >
        {subtitle}
      </p>
      {isDesktopMode && <TierFeatures features={features} />}
    </div>
  );
};

export default TierCardDesktop;
