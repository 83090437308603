import React, { useContext, useState } from "react";
import styles from "./styles.module.scss";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import {
  getCustomerData,
  getPriceFromQuery,
} from "../../../utils/getCustomerData";
import {
  useCreateMatchingSessionSubscriptionMutation,
  useCreateMemberMutation,
  useCreatePaymentMutation,
} from "../../../api/management";
import { CouponContext } from "../../../contexts/CouponContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  REACT_APP_ONBOARDING_URL,
  PAYMENT_ERROR_MESSAGES,
  REACT_APP_CHECKOUT_COMPLETE_URL,
} from "../../../utils/pricing-content";
import { getUrl } from "../../../utils/redirect";
import { useGetPriceQuery } from "../../../api/stripe";
import { NEW_ROUTES } from "../../../router";
import PaymentDetailsForm from "../../../components/PaymentDetailsForm";
import RitualAnalytics from "client-analytics";

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [createPayment] = useCreatePaymentMutation();
  const [createMember] = useCreateMemberMutation();
  const [createMatchingSessionSubscription] =
    useCreateMatchingSessionSubscriptionMutation();
  const { coupon } = useContext(CouponContext);
  const { state } = useLocation();
  const { originalPrice } = useGetPriceQuery(
    state?.price || getPriceFromQuery(),
  );
  const navigate = useNavigate();
  const [name, setName] = useState("");

  const handleError = (error: { error?: string; status_code?: number }) => {
    setLoading(false);
    setError(error?.error || PAYMENT_ERROR_MESSAGES[error?.status_code || 500]);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!name) {
      setError("Name field is required");
      return;
    }

    setError("");
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();

    if (submitError) {
      handleError({ error });
      return;
    }

    const customerData = getCustomerData({
      initialAmount: originalPrice,
      postgres_plan_id: state?.postgres_plan_id,
      tier_type: state?.tier,
      price: state?.price,
    });

    if (customerData) {
      try {
        const { type, clientSecret, user_data, paymentIntentId } =
          await createPayment({
            ...customerData,
            coupon: coupon?.id,
          }).unwrap();

        const url = getUrl(
          customerData.price,
          customerData.tier_type,
          customerData.postgres_plan_id,
          customerData.initialAmount,
          coupon?.percentOff,
        );

        const finishPayment =
          type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;

        const { error } = await finishPayment({
          elements,
          clientSecret: clientSecret,
          confirmParams: {
            return_url: REACT_APP_ONBOARDING_URL + url, // this field can't be empty
            payment_method_data: {
              billing_details: {
                name: name,
              },
            },
          },
          redirect: "if_required", // param to not redirect anywhere after payment and proceed with member creation
        });

        const eventName = `member-payment-confirmation-${
          window.location.origin.includes("dev") ? "dev" : "prod"
        }`;

        if (error) {
          if (!window.location.origin.includes("localhost")) {
            window.analytics?.track(eventName, {
              customer_data: user_data,
              error: error?.message || error,
              status_code: 700,
            });
            RitualAnalytics.track(eventName, {
              customer_data: user_data,
              error: error?.message || error,
              status_code: 700,
            });
          }
          handleError({ error: error?.message });
        } else {
          if (!window.location.origin.includes("localhost")) {
            window.analytics?.track(eventName, {
              customer_data: user_data,
              error: "",
              status_code: 200,
            });
            RitualAnalytics.track(eventName, {
              customer_data: user_data,
              error: "",
              status_code: 200,
            });
          }
          if (paymentIntentId) {
            await createMatchingSessionSubscription(user_data);
          }
          try {
            await createMember(user_data).unwrap();
            window.location.href = REACT_APP_CHECKOUT_COMPLETE_URL + url;
          } catch (e: any) {
            navigate(NEW_ROUTES.MEMBER_CREATION_ERROR, { state });
            setLoading(false);
          }
        }
      } catch (error: any) {
        handleError(error?.data?.error?.status_code);
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <PaymentDetailsForm
        handleSubmit={handleSubmit}
        error={error}
        name={name}
        onNameChange={(e) => setName(e.target.value)}
        isLoading={isLoading}
      />
    </div>
  );
};

export default PaymentForm;
