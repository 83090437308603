import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useGetPlansQuery } from "../../api/cms";
import useCurrentPlan from "../../hooks/useCurrentPlan";
import styles from "./styles.module.scss";
import { NEW_ROUTES } from "../../router";

const Switch = () => {
  const { data } = useGetPlansQuery();
  const currentPlan = useCurrentPlan();
  const navigate = useNavigate();
  const [state, setState] = useState<any>();

  useEffect(() => {
    document.fonts.ready.then(() => {
      if (!currentPlan) return;
      const activeElement = document.getElementById(`plan-${currentPlan.id}`);
      setState(activeElement);
    });
  }, [currentPlan]);

  const getStyle = () => {
    if (!state) return {};
    return {
      left: state?.offsetLeft + "px",
      width: state?.clientWidth + "px",
    };
  };

  return (
    <div className={styles.wrapper}>
      {state && <div className={styles.switch} style={getStyle()} />}
      {data?.map((item) => (
        <div
          id={`plan-${item.id}`}
          key={item.id}
          className={item.id === currentPlan.id ? styles.active : ""}
          onClick={() => navigate(NEW_ROUTES.HOME, { state: { plan: item } })}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};

export default Switch;
