import { useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import useCurrentPlan from "../../hooks/useCurrentPlan";
import { useGetTiersQuery } from "../../api/cms";
import useCurrentTier from "../../hooks/useCurrentTier";
import { getSubscriptionFromSearchParams } from "../../utils/pricing-content";
import { NEW_ROUTES } from "../../router";
import TierCardDesktop from "../TierCardDesktop";

const TiersList = () => {
  const plan = useCurrentPlan();
  const { data: tiers } = useGetTiersQuery(String(plan?.id));
  const tier = useCurrentTier();
  const navigate = useNavigate();
  const { search } = useLocation();
  const subscription = getSubscriptionFromSearchParams(search);

  const Routes = {
    Individual: NEW_ROUTES.SELECT_INDIVIDUAL_PLAN,
    Couple: NEW_ROUTES.SELECT_COUPLE_PLAN,
  };

  return (
    <div className={styles.wrapper}>
      {tiers?.map((item) => (
        <div
          key={item.id}
          id={`tier_item-${item.id}`}
          onClick={() =>
            navigate(subscription ? Routes[subscription] : NEW_ROUTES.HOME, {
              state: { tier: item, plan, step: 1 },
            })
          }
        >
          <TierCardDesktop
            initialPrice={String(item.initial_price)}
            title={item.Name}
            subtitle={item.description}
            selected={tier.id === item.id}
            image={item.image}
            length={tiers.length}
            features={item.features_list}
          />
        </div>
      ))}
    </div>
  );
};

export default TiersList;
