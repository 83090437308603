import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Tooltip } from "react-tooltip";
import Info from "../../assets/icons/Info";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";

const MoneyBackBanner = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const isDesktopMode = useIsDesktopMode();

  const toggleTooltip = () => {
    setTooltipVisible((prevState) => !prevState);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <p>14-day money back guarantee.</p>
        <button
          data-tooltip-id="guarantee-tooltip"
          data-tooltip-content="You must attend first session and request refund within 14 days of purchase to qualify."
          onClick={!isDesktopMode ? toggleTooltip : undefined}
          onMouseEnter={isDesktopMode ? toggleTooltip : undefined}
          onMouseLeave={isDesktopMode ? toggleTooltip : undefined}
        >
          <Info />
        </button>
        <Tooltip
          id="guarantee-tooltip"
          className={styles.tooltip}
          clickable
          isOpen={tooltipVisible}
        />
      </div>
      <p>
        If you’re not satisfied after your first session, cancel risk-free.{" "}
      </p>
    </div>
  );
};

export default MoneyBackBanner;
