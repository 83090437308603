import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "../../components";
import useCurrentPlan from "../../hooks/useCurrentPlan";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import PricesList from "./PricesList";
import MoneyBackBanner from "../../components/MoneyBackBanner";
import { NEW_ROUTES } from "../../router";
import PageWrapper from "../../components/PageWrapper";

const Prices = () => {
  const plan = useCurrentPlan();
  const location = useLocation();
  const isDesktopMode = useIsDesktopMode();
  const navigate = useNavigate();

  const tier = location.state?.tier;

  useEffect(() => {
    if (isDesktopMode) navigate(NEW_ROUTES.HOME);
  }, [isDesktopMode, navigate]);

  if (!tier) return <></>;

  return (
    <PageWrapper>
      <Typography variant="header">{plan.second_title}</Typography>
      <MoneyBackBanner />
      <PricesList />
    </PageWrapper>
  );
};

export default Prices;
