import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetPlansQuery } from "../../../api/cms";
import { Plan } from "../../../api/types";
import useCurrentPlan from "../../../hooks/useCurrentPlan";
import styles from "./styles.module.scss";
import { NEW_ROUTES } from "../../../router";

const PlanList = () => {
  const { data } = useGetPlansQuery();
  const navigate = useNavigate();
  const state = useLocation();
  const currentPlan = useCurrentPlan();
  const [loaded, setLoaded] = useState<{ [key: string]: boolean }>({});

  const handleClick = (plan: Plan) => () => {
    navigate(NEW_ROUTES.HOME, { state: { ...state, plan } });
  };

  return (
    <div className={styles.plans_wrapper}>
      {data?.map((item) => (
        <div
          key={item.id}
          id={`plan_item-${item.id}`}
          onClick={handleClick(item)}
          className={`${styles.plan_card} ${
            item.id === currentPlan.id ? styles.plan_card_selected : ""
          }`}
        >
          <p>{item.card_title}</p>
          <div className={styles.divider} />
          <img
            src={item.card_image}
            alt="card_image"
            style={
              loaded[item.card_image] ? {} : { width: "180px", height: "100px" }
            }
            onLoad={() =>
              setLoaded((prev) => ({ ...prev, [item.card_image]: true }))
            }
          />
        </div>
      ))}
    </div>
  );
};

export default PlanList;
