import React, { useContext, useEffect } from "react";
import CheckoutDetails from "./CheckoutDetails";
import PaymentForm from "./PaymentForm";
import styles from "./styles.module.scss";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useGetPriceQuery } from "../../api/stripe";
import { useLocation } from "react-router-dom";
import {
  getPriceWithCoupon,
  REACT_APP_STRIPE_PUBLIC_KEY,
} from "../../utils/pricing-content";
import { CouponContext } from "../../contexts/CouponContext";
import { getPriceFromQuery } from "../../utils/getCustomerData";
import RitualAnalytics from "client-analytics";

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

const CheckoutPage = () => {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    RitualAnalytics.track("new_checkout", {
      email: urlSearchParams.get("email"),
      coupon: urlSearchParams.get("coupon"),
    });
  }, []);

  const { state } = useLocation();
  const { originalPrice } = useGetPriceQuery(
    state?.price || getPriceFromQuery() || "",
  );
  const { coupon } = useContext(CouponContext);
  const amount = originalPrice
    ? getPriceWithCoupon(originalPrice, coupon) * 100
    : 0;

  const options = {
    mode: "subscription",
    amount,
    currency: "usd",
  } as StripeElementsOptions;

  return (
    <div className={styles.wrapper}>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutDetails />
        <PaymentForm />
      </Elements>
    </div>
  );
};

export default CheckoutPage;
