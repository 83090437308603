import React from "react";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import DesktopLayout from "../Home/DesktopLayout";
import MobileLayout from "./MobileLayout";
import PageWrapper from "../../components/PageWrapper";

const Plans = () => {
  const isDesktopMode = useIsDesktopMode();

  return (
    <PageWrapper>
      {isDesktopMode ? <DesktopLayout /> : <MobileLayout />}
    </PageWrapper>
  );
};

export default Plans;
