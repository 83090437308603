import styles from "./styles.module.scss";
import React from "react";
import BackButton from "../BackButton";

const DesktopHeader = () => {
  return (
    <div>
      <div className={styles.logo_wrapper}>
        <BackButton showLogo={true} style={{ margin: "0 40px" }} />
      </div>
      <div
        className={styles.desktop_title}
        style={{ maxWidth: "initial", whiteSpace: "break-spaces" }}
      >
        Your relationship is <span>worth it.</span>
        {"\n"}Let’s build your <span>custom plan.</span>
      </div>
    </div>
  );
};

export default DesktopHeader;
