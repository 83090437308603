import { useGetPriceQuery } from "../../api/stripe";
import OriginalPrice from "../OriginalPrice";
import styles from "./styles.module.scss";
import { useLocation } from "react-router-dom";
import { Plans } from "../../hooks/useCurrentStep";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import React, { useContext } from "react";
import {
  getPriceWithCoupon,
  getSubscriptionFromSearchParams,
} from "../../utils/pricing-content";
import { CouponContext } from "../../contexts/CouponContext";

interface PlanCardDesktopProps {
  title: string;
  subtitle: string;
  selected: boolean;
  initialPrice: string;
  image: string;
  length: number;
  features: string;
}

const PlanCardDesktop = ({
  title,
  subtitle,
  selected,
  initialPrice,
  image,
  length,
}: PlanCardDesktopProps) => {
  const { data: price, originalPrice } = useGetPriceQuery(initialPrice);
  const { coupon } = useContext(CouponContext);
  const priceWithCoupon = getPriceWithCoupon(price, coupon);
  const { search } = useLocation();
  const subscription = getSubscriptionFromSearchParams(search);
  const isDesktopMode = useIsDesktopMode();

  return (
    <div
      className={`${styles.wrapper} ${
        selected ? styles["wrapper-selected"] : ""
      } ${length <= 1 ? styles["wrapper-single"] : ""} ${
        styles.wrapper_repackaging
      }`}
    >
      <div className={styles.header_wrapper}>
        <div className={styles.card_title_block}>
          <div>
            <h4 className={styles.plan_title}>Weekly</h4>
            <h1
              className={`${styles.title} ${
                subscription === Plans.Couple ? styles.new_pricing_title : ""
              }`}
            >
              {title}
            </h1>
          </div>
          <div className={styles.price_description}>
            <p className={styles.starting_at}>Starting at</p>
            <div className={`${styles.price} ${styles.price_repackaging}`}>
              <OriginalPrice
                price={priceWithCoupon}
                originalPrice={originalPrice}
              />

              {!isDesktopMode ? (
                <h1 className={styles.title}>${priceWithCoupon}</h1>
              ) : (
                <h2>${priceWithCoupon}</h2>
              )}
            </div>
            <p className={styles.first_month}>{`${
              isDesktopMode ? "per" : ""
            } first month`}</p>
          </div>
        </div>
        {isDesktopMode && <img src={image} alt="card_image" />}
      </div>
      <span className={styles.green_text}>
        Matching session discount applied
      </span>
      <p
        style={{ paddingRight: length <= 1 ? 0 : "32px" }}
        className={styles.description}
      >
        {subtitle}
      </p>
    </div>
  );
};

export default PlanCardDesktop;
