import { Switch } from "../../components";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import Title from "../../components/Title";
import MoneyBackBanner from "../../components/MoneyBackBanner";
import DesktopLayout from "./DesktopLayout";
import Tiers from "./Tiers";
import PageWrapper from "../../components/PageWrapper";

const HomePage = () => {
  const isDesktopMode = useIsDesktopMode();

  return (
    <PageWrapper>
      {isDesktopMode ? (
        <DesktopLayout />
      ) : (
        <>
          <Switch />
          <Title variant="header" />
          <MoneyBackBanner />
          <Tiers />
        </>
      )}
    </PageWrapper>
  );
};

export default HomePage;
