import { useGetPriceQuery } from "../../api/stripe";
import OriginalPrice from "../OriginalPrice";
import Typography from "../Typography";
import styles from "./styles.module.scss";

interface TierCardProps {
  title: string;
  subtitle: string;
  priceId: string;
  selected: boolean;
}

const TierCard = ({ title, subtitle, priceId, selected }: TierCardProps) => {
  const { data: price, originalPrice } = useGetPriceQuery(priceId);

  return (
    <div
      className={`${selected ? styles["wrapper-selected"] : ""} ${
        styles.wrapper
      }`}
    >
      <div>
        <p className={styles.starting_at}>Starting at</p>
        <div className={styles.price_wrapper}>
          <Typography variant="title">{title}</Typography>
          <div>
            <OriginalPrice price={price} originalPrice={originalPrice} />
            <Typography variant="price">${price}</Typography>
            <Typography>{"/month"}</Typography>
          </div>
        </div>
      </div>
      <Typography>{subtitle}</Typography>
    </div>
  );
};

export default TierCard;
