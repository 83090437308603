import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { BANNER_LOADED_NAME, URL_PATH_QP } from "./constants";

const AffiliateBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [bannerValid, setBannerValid] = useState(false);

  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );

  useEffect(() => {
    if (!queryParams.get(URL_PATH_QP)) {
      return;
    }

    window.addEventListener("message", (message) => {
      if (message.data.name === BANNER_LOADED_NAME) {
        setBannerValid(true);
      }
    });
    setShowBanner(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showBanner && (
        <iframe
          className={
            bannerValid ? styles.affiliate_banner : styles.invalid_banner
          }
          style={{ border: "none" }}
          id="affiliate_banner"
          title="Affiliate Banner"
          src={`${process.env.REACT_APP_RITUAL_DOMAIN}/affiliates/${queryParams
            .get(URL_PATH_QP)
            ?.toLowerCase()}`}
        />
      )}
    </>
  );
};

export default AffiliateBanner;
