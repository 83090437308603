import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { REACT_APP_STRIPE_PUBLIC_KEY } from "../../utils/pricing-content";
import styles from "./styles.module.scss";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import BackButton from "../../components/BackButton";
import UpdatePaymentDetailsForm from "./UpdatePaymentDetailsForm";

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

const UpdatePaymentDetails = () => {
  const options = {
    paymentMethodCreation: "manual",
    mode: "setup",
    currency: "usd",
  } as StripeElementsOptions;

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <BackButton showLogo={true} />
        <Elements stripe={stripePromise} options={options}>
          <UpdatePaymentDetailsForm />
        </Elements>
      </div>
    </div>
  );
};

export default UpdatePaymentDetails;
