import React, { createContext, ReactNode, useEffect, useState } from "react";
import { useLazyGetCouponQuery } from "../api/management";

export interface Coupon {
  id: string;
  name: string;
  amount_off?: number;
  percentOff: number;
}

interface CouponContextType {
  coupon?: Coupon;
  setCoupon: React.Dispatch<React.SetStateAction<Coupon | undefined>>;
}

export const CouponContext = createContext<CouponContextType>(
  {} as CouponContextType,
);

export const CouponProvider = ({ children }: { children: ReactNode }) => {
  const [coupon, setCoupon] = useState<Coupon | undefined>(undefined);
  const [getCoupon] = useLazyGetCouponQuery();

  useEffect(() => {
    applyCouponFromQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyCouponFromQuery = async () => {
    const params = new URLSearchParams(window.location.search);
    const coupon = params.get("coupon");
    const mode = params.get("mode");

    if (coupon && !mode) {
      const response = await getCoupon(coupon).unwrap();
      setCoupon({
        name: coupon,
        percentOff: response.percent_off,
        id: response.id,
        amount_off: response.amount_off,
      });
    }
  };

  return (
    <CouponContext.Provider value={{ coupon, setCoupon }}>
      {children}
    </CouponContext.Provider>
  );
};
