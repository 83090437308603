import { UnlockGrowthBanner } from "../../../components";
import MainSection from "./MainSection";

const DesktopLayout = () => {
  return (
    <>
      <MainSection />
      <UnlockGrowthBanner />
    </>
  );
};

export default DesktopLayout;
