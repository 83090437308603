import React from "react";
import Back from "../../assets/icons/Back";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import OurRitualLogo from "../../assets/icons/OurRitualLogo";

const BackButton = ({
  showLogo,
  style,
}: {
  showLogo?: boolean;
  style?: { [key: string]: any };
}) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(-1);
  };

  return (
    <div
      id="site_back_button"
      style={style}
      className={styles.wrapper}
      onClick={onClick}
    >
      <Back style={{ marginRight: "auto", transform: "scale(1.3666666667)" }} />
      {showLogo && <OurRitualLogo style={{ marginRight: "auto" }} />}
    </div>
  );
};

export default BackButton;
