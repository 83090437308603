import { useLocation } from "react-router-dom";
import { useGetTiersQuery } from "../api/cms";
import useCurrentPlan from "./useCurrentPlan";

const useCurrentTier = () => {
  const plan = useCurrentPlan();
  const location = useLocation();
  const { data: tiers } = useGetTiersQuery(String(plan?.id), { skip: !plan });
  return (
    location.state?.tier ||
    tiers?.find(({ id }) => id === plan.default_tier?.id) ||
    tiers?.[0]
  );
};

export default useCurrentTier;
